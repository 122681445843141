import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { LoggerService, Logger } from 'src/app/shared/services/logger.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService implements ErrorHandler {
  public liveError$: BehaviorSubject<string>;
  private logger: Logger; components; columnDefs;

  constructor(private loggerFactory: LoggerService) {
    this.logger = loggerFactory.getLogger("GlobalErrorHandlerService")
    this.liveError$ = new BehaviorSubject('');
  }

  handleError(error: Error) {
    let message = error.message ? error.message : error.toString();
    message = (message.trim) ? message.trim() : message;
    //debugger
    if (error instanceof HttpErrorResponse) {
      // Do NOT handle the http error - they are already handled by interceptor
      return;
    }
    // if needed an API can be added here to also log some of the errors in server
    this.logger.error(`general error, message : ${message}, stack : `, {
      error_type: 'General Error',
      stack: error.stack
    })

    if (!environment.production) {
      this.liveError$.next(message);
      if(!message.includes('permission'))
      console.error(error);
    }
  }
}
